/*

Snapshot Template
http://www.templatemo.com/tm-493-snapshot

*/


/* Body 
------------------------------------- */
body {
  background: #fff;
  font-family: 'Humanist-777-Bt', sans-serif;
  font-size: 14px;
  line-height: 24px;
  position: relative;
}

html,
body {
  width: 100%;
  overflow-x: hidden;
}

html {
  font-size: 100%;
}

.clear {
  clear: both;
  line-height: 1px;
  font-size: 1px
}

b,
strong {
  font-weight: 600;
}

a {
  cursor: pointer;
}

@font-face {
  font-family: 'Humnst777_blkcn_btblack';
  src: url(../fonts/humnst777_blkcn_bt.eot);
  src: url(../fonts/humnst777_blkcn_bt.eot?#iefix) format('embedded-opentype'),
    url(../fonts/humnst777_blkcn_bt.woff2) format('woff2'),
    url(../fonts/humnst777_blkcn_bt.woff) format('woff'),
    url(../fonts/humnst777_blkcn_bt.ttf) format("truetype"),
    url(../fonts/humnst777_blkcn_bt.svg#humnst777_blkcn_bt) format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Humanist-777-Bt';
  src: url(../fonts/humanist-777-bt.eot);
  src: url(../fonts/humanist-777-bt.eot?#iefix) format('embedded-opentype'),
    url(../fonts/humanist-777-bt.woff2) format('woff2'),
    url(../fonts/humanist-777-bt.woff) format('woff'),
    url(../fonts/humanist-777-bt.ttf) format("truetype"),
    url(../fonts/humanist-777-bt.svg#humanist-777-bt) format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Calibri Regular';
  src: url(../fonts/calibri_regular-webfont.eot);
  src: url(../fonts/calibri_regular-webfont.eot?#iefix) format('embedded-opentype'),
    url(../fonts/calibri_regular-webfont.woff2) format('woff2'),
    url(../fonts/calibri_regular-webfont.woff) format('woff'),
    url(../fonts/calibri_regular-webfont.ttf) format("truetype"),
    url(../fonts/calibri_regular-webfont.svg#calibri_regular-webfont) format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Swiss721CondensedBT';
  src: url(../fonts/Swiss721CondensedBT.eot);
  src: url(../fonts/Swiss721CondensedBT.eot?#iefix) format('embedded-opentype'),
    url(../fonts/Swiss721CondensedBT.woff2) format('woff2'),
    url(../fonts/Swiss721CondensedBT.woff) format('woff'),
    url(../fonts/Swiss721CondensedBT.ttf) format("truetype"),
    url(../fonts/Swiss721CondensedBT.svg#Swiss721CondensedBT) format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FuturaMediumBT';
  src: url(../fonts/futuramediumbt-webfont.eot);
  src: url(../fonts/futuramediumbt-webfont.eot?#iefix) format('embedded-opentype'),
    url(../fonts/futuramediumbt-webfont.woff2) format('woff2'),
    url(../fonts/futuramediumbt-webfont.woff) format('woff'),
    url(../fonts/futuramediumbt-webfont.ttf) format("truetype"),
    url(../fonts/futuramediumbt-webfont.svg#futuramediumbt-webfont) format('svg');
  font-weight: normal;
  font-style: normal;
}

/*---------------------------------------
    Typorgraphy              
-----------------------------------------*/

h1,
h2,
h3,
h4 {
  font-weight: 400;
}

h1 {
  font-family: 'Athena Regular', sans-serif;
  font-size: 2rem;
  line-height: 3rem;
  text-transform: uppercase;
  padding-bottom: 8px;
}

h2 {
  font-family: 'Poppins', sans-serif;
  color: #e44c65;
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
}

h3 {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
}

p {
  font-family: 'Poppins', sans-serif;
  color: #888;
  font-size: 20px;
  line-height: 34px;
}

#home p,
#contact p,
footer p {
  color: #f9f9f9;
}

.btn {
  border: none;
  border-radius: 4px;
  transition: all 0.4s ease-in-out;
}

.btn:focus {
  background: #e44c65;
  border-color: transparent;
}

.btn-success {
  background: #e44c65;
  font-weight: 400;
  padding: 14px 32px;
  margin-top: 26px;
}

.btn-success:hover {
  background: #222;
}

a {
  color: #e44c65;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  text-decoration: none !important;
}

a:hover,
a:active,
a:focus {
  color: #e44c65;
  outline: none;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}


/*---------------------------------------
    All Section style              
-----------------------------------------*/

.section-title {
  padding-bottom: 62px;
}

#about .section-title {
  padding-top: 82px;
}

#about .section-title h1 {
  margin-bottom: 0px;
  padding-bottom: 0px;
}

#about,
#gallery,
#contact {
  padding-top: 120px;
  padding-bottom: 120px;
  position: relative;
}

#gallery,
#contact,
footer {
  text-align: center;
}

.navbar-header,
.navbar-collapse.collapse {
  visibility: visible !important;
}


/*---------------------------------------
    Pre Loader Style              
-----------------------------------------*/

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background: none repeat scroll 0 0 #fff;
}

.sk-spinner-pulse {
  width: 60px;
  height: 60px;
  background-color: #e44c65;
  border-radius: 100%;
  margin: 40px auto;
  -webkit-animation: sk-pulseScaleOut 1s infinite ease-in-out;
  animation: sk-pulseScaleOut 1s infinite ease-in-out;
}

@-webkit-keyframes sk-pulseScaleOut {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes sk-pulseScaleOut {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@-webkit-keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-100%);
    transition: all .3s
  }

  100% {
    opacity: .9;
    transform: translateY(0);
    transition: all .3s
  }
}

@-moz-keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-100%);
    transition: all .3s
  }

  100% {
    opacity: .9;
    transform: translateY(0);
    transition: all .3s
  }
}

@-ms-keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-100%);
    transition: all .3s
  }

  100% {
    opacity: .9;
    transform: translateY(0);
    transition: all .3s
  }
}

@-o-keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-100%);
    transition: all .3s
  }

  100% {
    opacity: .9;
    transform: translateY(0);
    transition: all .3s
  }
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-100%);
    transition: all .3s
  }

  100% {
    opacity: .9;
    transform: translateY(0);
    transition: all .3s
  }
}

to {
  opacity: 1;
  transform: none
}


.container_inn {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;
}

#wrapper {
  width: 100%;
  margin: 0;
  padding: 0;
}

.container {
  width: 100% !important;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
}
.vid_intro {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  position: relaive;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.neu_slide {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  position: relative;
  background: url("../images/Index/Home_Bg.png")no-repeat left top;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.neu_slide a {
  width: 100%;
}

.neu_slide img {
  margin: 0 auto;
  display: block;
  width: auto;
}

.slick-prev:before,
.slick-next:before {
  color: #0c5685 !important;
  transition: all .4s ease-in;
}

.slick-next:focus:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-prev:hover:before {
  color: #36cfef !important;
}

.slick-track {
  height: 100vh;
  overflow: hidden;
}

.slick-slide {
  min-height: auto;
  overflow: hidden;
}

.main_page {
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 0;
}
.intro_video {
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 0;
}

.neou_links {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  padding: 0;
  position: relative;
  background: url("../images/Index/Home_Bg.png")no-repeat left top;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle_img {
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  padding: 0;
  position: relative;
}

.circle_img img {
  width: auto;
  height: auto;
  margin: 0 auto;
  display: block;
}

.box {
  display: inline-block;
}

.circle_img .box {
  width: 85px;
  height: 75px;
}

.circle_img .active span.numbers,
.circle_img .active1 span.numbers {
  width: 22px;
  height: 26px;
  background: url(../images/comments_bg.png)no-repeat left top;
  background-size: 100% 100%;
  text-align: center;
  line-height: 19px;
  font-size: 12px;
  color: #FFF;
  font-weight: 600;
  display: block;
  position: relative;
}

.circle_img .anchr1 {
  position: absolute;
  right: 46%;
  top: -3.3%;
}

.circle_img .anchr2 {
  position: absolute;
  right: 46.5%;
  top: 185px;
}

.circle_img .anchr3 {
  position: absolute;
  right: 46.5%;
  top: 37.4%;
}

.circle_img .anchr4 {
  position: absolute;
  right: 46.5%;
  top: 52.1%;
}

.circle_img .anchr5 {
  position: absolute;
  right: 46.5%;
  top: 67.5%;
}

.circle_img a.anchr6 {
  position: absolute;
  right: 46.5%;
  top: 82.4%;
}

.circle_img a.anchr7 {
  position: absolute;
  right: 33%;
  top: 15.5%;
}

.circle_img a.anchr8 {
  position: absolute;
  right: 46%;
  top: -3.3%;
  width: 150px !important;
  height: 75px;
}

.circle_img a.anchr9 {
  position: absolute;
  right: 25%;
  top: 7.3%;
  width: 197px !important;
  height: 75px;
}

.circle_img a.anchr10 {
  position: absolute;
  right: 10%;
  top: 29.9%;
  width: 257px !important;
  height: 75px;
}

.circle_img a.anchr11 {
  position: absolute;
  right: 8%;
  top: 46.9%;
  width: 274px !important;
  height: 75px;
}

.circle_img a.anchr12 {
  position: absolute;
  right: 9.5%;
  top: 72%;
  width: 315px !important;
  height: 75px;
}

.circle_img a.anchr13 {
  position: absolute;
  right: 19.5%;
  top: 22.8%;
}

.circle_img a.anchr14 {
  position: absolute;
  right: 27.5%;
  top: 90.4%;
  width: 247px !important;
  height: 75px;
}

.circle_img a.anchr15 {
  position: absolute;
  right: 56.5%;
  top: 89.1%;
  width: 251px !important;
  height: 75px;
}

.circle_img a.anchr16 {
  position: absolute;
  right: 67.5%;
  top: 71.5%;
  width: 240px !important;
  height: 75px;
}

.circle_img a.anchr17 {
  position: absolute;
  right: 72.5%;
  top: 49%;
  width: 210px !important;
  height: 75px;
}

.circle_img a.anchr18 {
  position: absolute;
  right: 73%;
  top: 27.7%;
  width: 197px !important;
  height: 75px;
}

.circle_img a.anchr19 {
  position: absolute;
  right: 63%;
  top: 7.4%;
  width: 179px;
}
.circle_img a.anchr20 {
  position: absolute;
  right: 6%;
  top: 60.4%;
}



.circle_img a.anchr28 {
  position: absolute;
  right: 46%;
  top: -3.3%;
  width: 150px !important;
  height: 75px;
}

.circle_img a.anchr29 {
  position: absolute;
  right: 25%;
  top: 7.3%;
  width: 197px !important;
  height: 75px;
}

.circle_img a.anchr30 {
  position: absolute;
  right: 9%;
  top: 27.9%;
  width: 257px !important;
  height: 75px;
}

.circle_img img.anchr30_1 {
  position: absolute;
    right: 19.6%;
    top: 38.9%;
    width: 115px !important;
    height: 44px;
    border: none;

  /* border-radius: 10px; */
  /* background: url("../images/Index/SUR_gray.png")no-repeat left top; */
}

.circle_img img.anchr30_2 {
  position: absolute;
    right: 11%;
    top: 38.9%;
    width: 115px !important;
    height: 45.5px;
    border: none;
  /* border-radius: 10px; */
  /* background: url("../images/Index/CP_gray.png")no-repeat left top; */
}

.circle_img img.anchr31_1 {
  position: absolute;
  right: 19.6%;
  top: 61.9%;
  width: 115px !important;
  height: 44px;
  border: none;
  /* border-radius: 10px; */
  /* background: url("../images/Index/SUR_gray.png")no-repeat left top; */
}

.circle_img img.anchr31_2 {
  position: absolute;
    right: 11%;
    top: 61.9%;
    width: 115px !important;
    height: 45.5px;
    border: none;
  /* border-radius: 10px; */
  /* background: url("../images/Index/CP_gray.png")no-repeat left top; */
}

.circle_img a.anchr31 {
  position: absolute;
  right: 8%;
  top: 51.9%;
  width: 274px !important;
  height: 75px;
}

.circle_img a.anchr32 {
  position: absolute;
  right: 18.5%;
  top: 79%;
  width: 242px !important;
  height: 75px;
}

.circle_img a.anchr33 {
  position: absolute;
  right: 19.5%;
  top: 22.8%;
}

.circle_img a.anchr34 {
  position: absolute;
  right: 41.5%;
  top: 90.4%;
  width: 247px !important;
  height: 75px;
}

.circle_img a.anchr35 {
  position: absolute;
  right: 61.5%;
  top: 79.1%;
  width: 188px !important;
  height: 75px;
}

.circle_img a.anchr36 {
  position: absolute;
  right: 71.5%;
  top: 54.5%;
  width: 223px !important;
  height: 75px;
}

.circle_img a.anchr37 {
  position: absolute;
  right: 71.5%;
  top: 32%;
  width: 210px !important;
  height: 75px;
}

.circle_img a.anchr38 {
  position: absolute;
  right: 62%;
  top: 7.7%;
  width: 210px !important;
  height: 75px;
}


.circle_img .anchr1 .active1 span.numbers {
  top: -4px;
  left: 7px;
}

.circle_img .anchr2 .active1 span.numbers {
  top: -4px;
  left: 7px;
}

.circle_img .anchr3 .active1 span.numbers {
  top: -2px;
  left: 7px;
}

.circle_img .anchr4 .active1 span.numbers {
  top: -1px;
  left: 7px;
}

.circle_img .anchr5 .active1 span.numbers {
  top: -4px;
  left: 7px;
}

.circle_img a.anchr6 .active1 span.numbers {
  top: -4px;
  left: 7px;
}


.circle_img a.anchr7 .active1 span.numbers {
  top: -5px;
  left: 7px;
}

.circle_img a.anchr8 .active span.numbers {
  top: -8px;
  left: 60px;
}

.circle_img a.anchr9 .active span.numbers {
  top: -20px;
  left: 75px;
}

.circle_img a.anchr10 .active span.numbers {
  top: -15px;
  left: 105px;
}

.circle_img a.anchr11 .active span.numbers {
  top: -8px;
  left: 110px;
}


.circle_img a.anchr12 .active span.numbers {
  top: -10px;
  left: 120px;
}

.circle_img a.anchr13 .active span.numbers {
  top: -4px;
  left: 7px;
}

.circle_img a.anchr14 .active span.numbers {
  top: -25px;
  left: 110px;
}

.circle_img a.anchr15 .active span.numbers {
  top: -8px;
    left: 110px;
}

.circle_img a.anchr16 .active span.numbers {
  top: -8px;
  left: 85px;
}

.circle_img a.anchr17 .active span.numbers {
  top: -8px;
    left: 86px;
}

.circle_img a.anchr18 .active span.numbers {
  top: -8px;
  left: 85px;
}

.circle_img a.anchr19 .active span.numbers {
  top: -20px;
  left: 70px;
}
.circle_img a.anchr20 .active span.numbers {
  top: -5px;
  left: 7px;
}


.circle_img a.anchr28 .active span.numbers {
  top: -8px;
  left: 60px;
}

.circle_img a.anchr29 .active span.numbers {
  top: -8px;
  left: 75px;
}

.circle_img a.anchr30 .active span.numbers {
  top: -8px;
  left: 105px;
}

.circle_img a.anchr31 .active span.numbers {
  top: -8px;
  left: 110px;
}


.circle_img a.anchr32 .active span.numbers {
  top: -8px;
  left: 90px;
}

.circle_img a.anchr33 .active span.numbers {
  top: -4px;
  left: 7px;
}

.circle_img a.anchr34 .active span.numbers {
  top: -20px;
  left: 110px;
}

.circle_img a.anchr35 .active span.numbers {
  top: -8px;
    left: 85px;
}

.circle_img a.anchr36 .active span.numbers {
  top: -8px;
  left: 85px;
}

.circle_img a.anchr37 .active span.numbers {
  top: -8px;
    left: 86px;
}

.circle_img a.anchr38 .active span.numbers {
  top: -8px;
  left: 85px;
}


.circle_img .active,
.circle_img .active1 {
  width: 100%;
  height: 100%;
}

.rotate_button {
  width: 51px;
  position: absolute;
  top: -14px;
  left: 50px;
  cursor: pointer;
}

.rotate_button img {
  width: 30px;
  height: 30px;
  display: block;
  transition: 0.70s;
  -webkit-transition: 0.70s;
  -moz-transition: 0.70s;
  -ms-transition: 0.70s;
  -o-transition: 0.70s;
}

.rotate_button img:hover {
  transition: 0.70s;
  -webkit-transition: 0.70s;
  -moz-transition: 0.70s;
  -ms-transition: 0.70s;
  -o-transition: 0.70s;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.pushmenu,
.pushmenu-push {
  transition: all 0.5s ease;
}

.pushmenu-push {
  overflow-x: hidden;
  right: 0;
}

.single-item {
  width: 100%;
}

.slick-prev {
  left: 0 !important;
}

.slick-next,
.slick-prev {
  opacity: 1 !important;
  z-index: 9;
  /* display: none; */
}

.slick-prev:before,
.slick-next:before {
  opacity: 1 !important;
  color: #000000;
}

.slick-next {
  right: 0 !important;
}

.name_sect {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  padding: 0;
  background: url("../images/neu_bg.jpg")no-repeat left top;
  background-size: 100% 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.name_cont {
  width: auto;
  height: auto;
  position: relative;
}

.name_cont img {
  width: auto;
  height: auto;
  margin: 0 auto;
  padding: 0;
}

.name_cont a.anchr_sect {
  position: absolute;
  right: 19px;
  bottom: 15px;
  width: 40px;
  height: 40px;
  border-radius: 50px;
}

.caris_sect {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  background: url(../images/cari_img2.png) no-repeat left top;
  background-size: 100% 100%;
  position: relative;
}

.caris_cont1 {
  padding: 80px 0 12px;
}

.caris_cont1 img {
  margin: 0 auto;
  width: auto;
  height: auto;
}

.logo {
  overflow: hidden;
  position: relative;
  padding: 0 0 6px;
  width: 700px;
  display: block;
  margin: 0 auto;
}

.logo:before {
  content: '';
  position: absolute;
  top: 0;
  left: -85px;
  width: 50px;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  transform: skewX(-30deg);
  animation-name: slide;
  animation-duration: 4s;
  animation-timing-function: ease-in-out;
  animation-delay: .2s;
  /* animation-direction: alternate; */
  /* background: linear-gradient( to right, 
    rgba(255, 255, 255, 0.5) 0%, 
    rgba(255, 255, 255, 0.13) 77%,
     rgba(255, 255, 255, 0.5) 92%,
    rgba(255, 255, 255, 0.0) 100% ); */
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, .9) 50%, rgba(255, 255, 255, 0) 100%);
}

@keyframes slide {
  0% {
    left: -100;
    top: 0;
  }

  /* 50% {
      left: 120px;
      top: 0px;
    } */
  100% {
    left: 490px;
    top: 0;
  }
}

.caris_cont2 {
  margin: 0 0 70px;
}

.caris_cont2 span img {
  margin: 0 auto;
  display: block;
}

.caris_ft_liks {
  width: 100%;
  margin: 0;
  padding: 0 0 25px;
  position: absolute;
  bottom: 0;
  text-align: center;
}

.caris_ft_liks button {
  border: none;
  background: transparent;
  padding: 0 6px;
}


.oxetol_sect {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  position: relative;
}

.oxetol_cont {
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
}

.oxetol_blog {
  width: 100%;
  margin: 0;
  padding: 0;
}

.oxetol_blog_top {
  width: 100%;
  margin: 0;
}

.oxetol_blog_top_left {
  width: 200px;
  margin: 0;
  margin-top: -4px;
  float: left;
}

/* .oxetol_blog_top_left a {
  display: inline-block;
} */

.oxetol_blog_top_right {
  width: 730px;
  margin: 0;
  float: right;
  padding: 27px 0 0;
}

.menu_sect {
  width: 45px;
  text-align: left;
  margin: 0;
  padding: 0;
  display: inline-block;
  vertical-align: baseline;
  background-color: transparent;
  visibility: visible !important;
}

.menu_icon {
  width: auto;
  position: absolute;
  z-index: 9;
  display: block;
  top: 20px;
  right: 0px;
  padding-right: 10px;
}

.menu_icon a.home_img {
  vertical-align: top;
  position: relative;
  display: inline-block;
  z-index: 9999;
  float: right;
}

/* .open .menu_icon a.home_img i { color: #FFF;} */
.menu_icon a.home_img i {
  color: #f58220;
  font-size: 38px;
  transition: all 0.5s ease;
}

.menu_icon a.home_img:hover i,
.pushmenu-push.open .menu_icon a.home_img:hover i {
  color: #f3a832;
}

.open1 .menu_icon a.home_img i {
  transition: all 0.5s ease;
}
.oxetol_blog_top_right .marginright_22 {
  margin: 30px 0 30px 22px;
  display: block;
}
.oxetol_blog_top_right .topmarginright_22 {
  margin: 0px 0px 0px 22px;
  display: block;
}
.oxetol_blog_top_right .marginright_52 {
  margin: 0px 0px 0px -52px;
  display: block;
}
.oxetol_blog_top_right .marginright__50 {
  margin: 15px 0 15px -50px;
  display: block;
}
.oxetol_blog_top_right .marginright_50 {
  margin: 0px 0px 0px -50px;
  display: block;
}
.oxetol_blog_top_right .marginright_5 {
  margin: 30px 0 30px 5px;
  display: block;
}
.oxetol_blog_top_right .marginright_3 {
  margin: 0px 0px 0px -3px;
  display: block;
}
.oxetol_blog_top_right .marginright_0 {
  margin: 0px 0px 0px 0px;
  display: block;
}
.oxetol_blog_top_right .marginright_55 {
  margin: 30px 0 30px 1px;
  display: block;
}

.oxetol_blog_top_right .marginright_29 {
  margin: 0px 0px 0px -29px;
  display: block;
}
.oxetol_blog_top_right .marginright_36 {
  margin: 43px 0 30px -36px;
  display: block;
}

.oxetol_blog_top_right .marginright_30 {
  margin: 30px 0 30px -50px;
  display: block;
}
.oxetol_blog_top_right .marginright_25 {
  margin: 0px 0px 0px -15px;
  display: block;
}
.oxetol_blog_top_right .marginright_40 {
  margin: 30px 0 30px -40px;
  display: block;
}
.oxetol_blog_top_right .marginright_28 {
  margin: 0px 0px 0px -28px;
  display: block;
}
.oxetol_blog_top_right .marginright_20 {
  margin: 20px 0 0px -20px;
  display: block;
}
.oxetol_blog_top_right .margintop_30 {
  margin: 30px 0 30px -87px;
  display: block;
}


.oxetol_blog_top_right .margintop_0 {
  margin: 0px 0 30px -50px;
  display: block;
}
.oxetol_blog_top_right .margintop_7 {
  margin: 7px 0 30px -50px;
  display: block;
}


.oxetol_blog_top_right span {
  margin: 0 0 30px -80px;
  display: block;
}
.oxetol_blog_top_right.logo.margintop47 {
  margin: 0 0 47px -87px;
  display: block;
}
.oxetol_blog_top_right .margintop15 {
  margin: 0 0 15px -87px;
  display: block;
}
.oxetol_blog_top_right .margintop83 {
  margin: 69.5px 0 30px -87px;
  display: block;
}
.oxetol_blog_top_right .margintop40 {
  margin: 40.5px 0 30px -87px;
  display: block;
}

.oxetol_blog_top_right.logo.margintop45 {
  margin: 0 0 45px -87px;
  display: block;
}

.oxetol_blog_top_right.logo.logomarginleft50 {
  margin: 0 0 50px -87px;
  display: block;
}

.pushmenu {
  /*this is the nav*/
  background:linear-gradient(180deg, #ff3800 0%, #f1bf61 99%, #f1bf61 100%);
  text-align: center;
  width: 90px;
  height: auto;
  padding: 30px 0 6px;
  z-index: 1000;
  position: inherit;
  float: left;
  position: absolute;
  top: -37px;
}

.pushmenu h3 {
  color: #f1f1f1;
  font-size: 1.3em;
  font-weight: 400;
  padding: 15px 25px;
  margin: 0;
  background: #333;
  height: 16px;
}

.links {
  list-style-type: none;
  padding: 55px 0 0;
  margin: 0 auto;
  width: 78%;
}

.links>li {
  padding: 15px 0px;
  margin: 0;
  text-align: center;
  position: relative;
  border-bottom: 2px solid #ded4d0;
}

.links>li:last-child {
  border-bottom: none;
}

.links>li a {
  position: relative;
  display: block;
  /* drops the nav vertically*/
  color: #FFF !important;
  margin: 0;
  padding: 0;
  font-size: 18px;
  line-height: 17px;
  font-weight: 600;
  font-family: 'Calibri Regular', sans-serif;
  text-decoration: none;
  transition: all 0.5s ease;
  letter-spacing: 0px;
}

.links li a:hover {
  color: #fed806;
}

.links li.current-menu-item a {
  color: #fed806 !important;
}

.links li ul {
  list-style-type: none;
  padding: 0;
  width: 95%;
  margin: 20px auto 0;
  display: none;
}

.links li ul li {
  padding: 18px 20px;
  border-bottom: 1px solid #ebeeed;
}

.links li ul li:last-child {
  border-bottom: none;
}

.links li span {
  position: absolute;
  z-index: 99999;
  right: 33px;
  top: 30px;
  height: 14px;
  width: 8px;
  text-align: center;
  transition: all 0.5s ease;
  cursor: pointer;
  display: block;
}

.links li span img {
  width: 10px;
  height: 16px;
  display: block;
}

.links li span.rotate {
  transform: rotate(90deg);
}

.links>li>ul>li>a {
  position: relative;
  display: block;
  /* drops the nav vertically*/
  color: #000000;
  font-size: 16px;
  font-family: 'NexaRustSans-Black', sans-serif;
  text-decoration: none;
  transition: all 0.5s ease;
}

.pushmenu-right {
  right: -350px;
  opacity: 0;
}

.pushmenu-right.pushmenu-open {
  right: 0;
  opacity: 1;
}

.pushmenu-push {
  overflow-x: hidden;
  right: 0;
}

/*Transition*/

.pushmenu,
.pushmenu-push {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#nav_list {
  cursor: pointer;
  width: 24px;
  height: 17px;
}

#nav-list.active {
  background-position: -331px top;
}

#toggle {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  cursor: pointer;
  height: auto;
  padding: 0;
  margin: 7px 0 0 8px;
  width: 30px;
  z-index: 1000;
  box-sizing: border-box;
  z-index: 9999;
  display: block;
  transition: all 0.3s ease-in-out 0s;
  position: relative;
}

/* .active #toggle .patty, .active #toggle .patty::after, .active #toggle .patty::before  { background: #FFF;} */

#toggle .patty {
  background: #f58220;
  height: 5px;
  top: 0;
  margin: 0;
  transition: all 0.3s ease 0s;
  width: 30px;
  border-radius: 5px;
  position: relative;
  display: inline-block;
}

#toggle .patty::after,
#toggle .patty::before {
  background: #f58220;
  content: "";
  display: inline-block;
  height: 5px;
  position: absolute;
  transition: all 0.3s ease 0s;
  width: 30px;
  border-radius: 5px;
  z-index: -1;
}

#toggle .patty::before {
  top: -8px;
  right: 0px;
}

#toggle .patty::after {
  top: 8px;
  right: 0px;
}

#toggle:hover {
  transition: top 0.4s ease 0s;
}

#toggle:hover {
  opacity: 1;
}

#toggle:hover .patty::before {
  top: -8px;
}

#toggle:hover .patty::after {
  top: 8px;
}

.open1 .patty {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0) !important;
}

.open1 .patty::before {
  background: #FFF !important;
  top: 0 !important;
  transform: rotate(-45deg);
}

.open1 .patty::after {
  background: #FFF !important;
  top: 0 !important;
  transform: rotate(45deg);
}


/* .dream_sect {
  width: 100%;
  height: 27px;
  background: url(../images/neu_img21.png) no-repeat left top;
  background-size: 100% 100%;
  padding: 0 0 0 36px;
} */

.dream_sect1 {
  margin-top: -9px;
}

.oxetol_blog_middle {
  width: 100%;
  /* max-width: 870px; */
  margin: 0 auto;
  /* padding: 0 0 10px 90px; */
  position: relative;
}

.oxetol_blog_middle span {
  padding: 40px 0 0 0;
  display: block;
}

.oxetol_blog_middle_padding_top20 {
  padding: 0px 0 0 0;
  display: block;
}

.bar_section {
  width: 560px;
  margin: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
}

.bar_cont {
  width: 100%;
  height: 347px;
  background: no-repeat left top;
  margin: 0;
  padding: 0;
  border: none;
}

.bar_blog_abs {
  width: 492px;
  height: 228px;
  position: relative;
  left: 68px;
  top: 21px;
  overflow: hidden;
}

.bar1 {
  width: auto;
  display: block;
  position: absolute;
  left: 15px;
  top: 0;
}

.bar2 {
  width: auto;
  display: block;
  position: absolute;
  left: 109px;
  top: 0;
}

.bar3 {
  width: max-content;
  display: block;
  position: absolute;
  left: 202px;
  top: 0;
}

.bar4 {
  width: max-content;
  display: block;
  position: absolute;
  left: 294px;
  top: 0;
}

.bar5 {
  width: max-content;
  display: block;
  position: absolute;
  left: 385px;
  top: 0;
}

.bar6,
.bar7,
.bar8,
.bar9 {
  width: max-content;
  display: block;
  position: absolute;
}

.right_img {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 0 -24px;
  position: relative;

}

.oxetol_blog_footer {
  width: 100%;
  margin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 20px;
}

.oxetol_blog_footer_left {
  width: 60%;
  float: left;
  position: absolute;
  bottom: 10px;
  left: 80px;
}

.oxetol_blog_footer_left span {
  margin: 0 0 5px 630px;
  display: block;
  line-height: normal;
}
.oxetol_blog_footer_left .addleftpadding578 {
  margin: 0 0 5px 578px;
  display: block;
  line-height: normal;
}
.oxetol_blog_footer_left .addleftpadding520 {
  margin: 0 0 5px 520px;
  display: block;
  line-height: normal;
}

.oxetol_blog_footer_left .addleftmargin525 {
  margin: 0 0 5px 525px;
  display: block;
  line-height: normal;
}

.oxetol_blog_footer_left .addleftpadding830 {
  margin: 0 0 5px 770px;

}
.oxetol_blog_footer_left .addleftpadding552 {
  margin: 0 0 -59px 425px;

}
.oxetol_blog_footer_left .addleftpadding500 {
  margin: 0 0 -59px 500px;

}

.oxetol_blog_footer_left .addleftpadding497 {
  margin: 0 0 20px 430px;

}

.oxetol_blog_footer_left .addleftpadding7 {
  margin: 0 0 3px 480px;
  
}
.oxetol_blog_footer_left .addleftmargin410 {
  margin: 0 0 3px 410px;
  
}
.footer_left {
  width: 100%;
  margin: 0;
  padding: 0;
  float: left;
  position: relative;
}

.footer_left img {
  margin: -10px 0px 13px 0px;
    display: block;
    position: relative;
    z-index: 1;
    cursor: pointer;
    visibility: visible !important;
}

.open_sect {
  width: auto;
  margin: 0px 0px 0px -12px;
  padding: 23px 10px 0px 0px;
  background-color: #e3e3e3;
  border-radius: 35% 13px 13px 13px / 100% 13px 13px 13px;
  font-size: 10px;
  line-height: 21px;
  color: #414040;
  position: absolute;
  left: 0px;
  bottom: 4px;
  float: right;
  overflow: hidden;
}

.box-inner.dsr_box {
  width: auto;
  line-height: normal;
  padding-left:78px;
}

.open_sect button {
  font-size: 10px;
  line-height: 14px;
  font-weight: 500;
  color: #414040;
  margin: 0 17px 0 0;
  padding: 0;
  cursor: pointer;
  position: relative;
  border: none;
  background: transparent;
}

.oxetol_blog_footer_right {
  margin: 0;
  padding-top: 0;
  position: absolute;
  bottom: -7px;
  right: 89PX;
  width: auto;
  float: right;
}

.dream_sect img {
  padding: 6px 0 0;
}

.dream_sect1 img {
  padding: 5px 0 0;
}

.oxetol_blog_footer_right ul {
  list-style: none;
  width: 8px;
  padding: 0;
  margin: 0;
  position: absolute;
  bottom: 33px;
  right: 20px;
  list-style-type: none;
  display: flex; /* Use flexbox for horizontal alignment */
  justify-content: center; /* Center the dots horizontally */

}

.oxetol_blog_footer_right ul li {
  display: block;
  padding: 0px 10PX 0;
  position: relative;
} 

.oxetol_blog_footer_right ul li::after {
  content: '';
  width: 2px;
  height: 23px;
  display: block;
  position: absolute;
  left: 3px;
  top: 2px;
  background-color: #d9dbdc;
}

.oxetol_blog_footer_right ul li a {
  width: 15px;
  height: 15px;
  border-radius: 50px;
  display: block;
  background-color: #a7a9ac;
}

.oxetol_blog_footer_right ul li.active a,
.oxetol_blog_footer_right ul li a:hover {
  background-color: #f58220;
}

.oxetol_blog_footer_right ul li:first-child::after {
  display: none;
}


.com_pop {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  bottom: 0;
  text-align: center;
}

.com_pop .popup__content {
  width: auto;
  height: auto;
  overflow: auto;
  padding: 0;
  background: transparent;
  color: black;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
}

.com_pop .popup__content .close {
  position: absolute;
  right: 30px;
  top: 30px;
  width: 20px;
  display: none;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

.com_pop .popup__content .close span {
  cursor: pointer;
  position: fixed;
  width: 20px;
  height: 3px;
  background: #FFF;
}

.com_pop .popup__content .close span:nth-child(1) {
  transform: rotate(45deg);
}

.com_pop .popup__content .close span:nth-child(2) {
  transform: rotate(135deg);
}

.popup__content img {
  width: auto;
  height: auto;
}

.carispop1_1 .popup__content img,
.carispop3_1 .popup__content img {
  width: 65%;
  /* height: 680px; */
}

.canmat_pop1_1 .popup__content img {
  width: 80% !important;
}

.carispop3_1 .popup__content img {
  width: 80%;
}

.bar_section.caris_bar1 {
  width: 829px;
}
.bar_section.caris_bar.rightmargin135 {
  margin: 30px 0 0px 135px;
}
.bar_section.caris_bar.topmargin50 {
  margin: 50px 0 0px 135px;
}
.bar_section.caris_bar.topmargin60 {
  margin: 50px 0 0px 60px;
}
.bar_section.caris_bar {
  margin: 30px 0 0px 76px;
}
.bar_section.nitrest_bartopmargin55 {
  margin: 55px 0 0px 76px;
}
.bar_section.rifa_bartopmargin {
  margin: -40px 0 0px 76px;
}
.bar_section.caris_barmargintop60 {
  margin: 60px 0 0px 76px;
}
.bar_section.caris_barmargintop16 {
  margin: 30px 0 0px 76px;;
}
.bar_section.caris_barmarginleft48 {
  margin: 30px 0 0px 48px;;
}
.bar_section.caris_barmarginleft100 {
  margin: 30px 0 0px 100px;;
}
.bar_section.risu_graph {
  margin: 0px 0 0px 160px;
}

.bar_section.caris_bar1 .bar_cont {
  background: url(../images/cari_img34.png)no-repeat left top;
}

.bar_section.caris_bar .bar_blog_abs {
  /* height: 236px; */
  top: -279px;
}


.bar_section.caris_bar1 .bar_blog_abs {
  height: 236px;
  top: -20px;
}


.bar_section.caris_bar1 .bar1 {
  left: 30px;
  bottom: 0;
  top: initial;
}

.bar_section.caris_bar1 .bar2 {
  left: 107px;
  bottom: 0;
  top: initial;
}

.bar_section.caris_bar1 .bar3 {
  left: 197px;
  bottom: 0;
  top: initial;
}

.bar_section.caris_bar1 .bar4 {
  left: 282px;
  bottom: 0;
  top: initial;
}

.bar_section.caris_bar1 .bar5 {
  left: 366px;
  bottom: 0;
  top: initial;
}

.caris_slide1 .oxetol_blog_middle span {
  padding: 7px 0 15px;
}

.caris_slide2 .bar_cont {
  background: url(../images/cari_img43.png) no-repeat left top;
  height: 370px !important;
}

.caris_slide2 .bar_blog_abs {
  width: 500px;
  height: 215px;
  left: 54px;
  top: 3px;
}

.caris_slide2 .oxetol_blog_middle span {
  padding: 10px 0 8px;
  display: block;
}

.caris_slide3 .bar_section {
  width: 888px;
}

.caris_slide3 .bar_cont {
  background: url(../images/cari_img53.png) no-repeat left top;
  height: 370px !important;
}

.caris_slide3 .bar_blog_abs {
  width: 558px;
  height: 235px;
  left: 46px;
  top: 20px;
}

.oxetol_blog_footer_right button {
  border: none;
  background: transparent;
  margin-right: 60px;
  margin-top: -15px;
  padding: 0;
}

.caris_slide4 .bar_section {
  width: 912px;
}

.caris_slide4 .bar_cont {
  background: url(../images/cari_img68.png)no-repeat left top;
  height: 380px !important;
}

.caris_slide4 .bar_blog_abs {
  width: 426px;
  height: 226px;
  left: 94px;
  top: 13px;
}

.caris_slide4 .bar3 {
  left: 328px;
}

.caris_slide4 .bar2 {
  left: 175px;
}

.caris_slide4 .bar1 {
  left: 23px;
}

.caris_slide5 .oxetol_blog_top_right span,
.caris_slide6 .oxetol_blog_top_right span,
.caris_slide7 .oxetol_blog_top_right span,
.caris_slide8 .oxetol_blog_top_right span,
.caris_slide9 .oxetol_blog_top_right span {
  margin: 75px 0 40px 30px;
  display: block;
}

.caris_slide5 .bar_section {
  width: 880px;
}

.caris_slide5 .bar_cont {
  background: url(../images/cari_img77.png)no-repeat left top;
  height: 380px !important;
}

.caris_slide5 .bar1 {
  left: 2px;
  top: 3px;
}

.caris_slide6 .bar_section {
  width: 925px;
}

.caris_slide6 .bar_cont {
  background: url(../images/cari_img95.png)no-repeat left top;
  height: 380px !important;
}

.caris_slide6 .bar_blog_abs {
  width: 515px;
  left: 55px;
  top: 6px;
}

.caris_slide6 .bar2 {
  left: 12px;
  top: 0;
}

.caris_slide6 .bar3 {
  left: 76px;
  top: 0;
}

.caris_slide6 .bar4 {
  left: 141px;
  top: 0;
}

.caris_slide6 .bar5 {
  left: 205px;
  top: 0;
}

.caris_slide6 .bar6 {
  right: 206px;
  top: 0;
}

.caris_slide6 .bar7 {
  right: 140px;
  top: 0;
}

.caris_slide6 .bar8 {
  right: 76px;
  top: 0;
}

.caris_slide6 .bar9 {
  right: 13px;
  top: 0;
}

.dose_sect {
  width: 100%;
  margin: 0;
  padding: 0;
}

.dose_sect span {
  display: inline-block;
  width: auto;
}

.caris_slide8 .bar_section {
  width: 780px;
}

.caris_slide8 .bar_cont {
  background: url(../images/cari_img109.png)no-repeat left top;
  height: 380px !important;
}

.caris_slide8 .bar_blog_abs {
  left: 57px;
  top: 10px;
}

.caris_slide9 .bar_section {
  width: 780px;
}

.caris_slide9 .bar_cont {
  background: url(../images/cari_img116.png)no-repeat left top;
  height: 380px !important;
}

.caris_slide9 .bar_blog_abs {
  left: 62px;
  top: 5px;
}

.pana_sect .oxetol_blog_top_right {
  padding: 29px 0 0;
}

.pana_sect .oxetol_blog_top_right span {
  margin: 0 0 15px 35px;
  display: block;
}

.pana_sect .dream_sect img {
  padding: 5px 0 0;
}

.pana_sect .oxetol_blog_middle span img {
  margin: 0 auto;
}

.decades_sect span {
  display: inline-block;
}

.decades_sect {
  position: relative;
  width: 628px;
  margin: 0 auto;
}

.decades_sect span.pos_decade41 {
  width: auto;
  position: absolute;
  margin-left: -15px;
}

.decades_sect span.pos_decade42 {
  width: auto;
  position: absolute;
  margin-left: 338px;
}

.decades_sect span.pos_decade43 {
  position: absolute;
  left: 280px;
  top: 92px;
  padding: 0;
}

.decades_sect span.pos_decade44 {
  position: absolute;
  left: 280px;
  top: 205px;
  padding: 0;
}

.decades_sect span.pos_decade45 {
  position: absolute;
  left: 280px;
  top: 300px;
  padding: 0;
}

.decades_sect span.pos_decade50 {
  position: absolute;
  left: 102px;
  top: 60px;
  padding: 0;
}

.btnQrPana2 {
  border: 0;
  padding: 0;
  left: 400px;
  top: 300px;
  position: absolute;
}

.btnQrPark1 {
  border: 0;
  padding: 0;
  background: none;
}

.decades_sect span.pos_decade51 {
  position: absolute;
  left: 156px;
  top: 120px;
  padding: 0;
}

.decades_sect span.pos_decade52 {
  position: absolute;
  left: 150px;
  top: 180px;
  padding: 0;
}

.decades_sect span.pos_decade53 {
  position: absolute;
  left: 735px;
  top: 69px;
  padding: 0;
}

.decades_sect span.pos_decade54 {
  position: relative;
  z-index: 9;
}

.pana_sect .oxetol_blog_middle span {
  padding: 7px 0 15px;
  display: block;
}

.pana_sect .oxetol_blog_footer_right button {
  margin-right: 60px;
}

.pana_sect .oxetol_blog_footer_right ul {
  bottom: 28px;
}

.pana_sect .oxetol_blog_footer_right ul li.active a,
.pana_sect .oxetol_blog_footer_right ul li a:hover {
  background-color: #006866;
}

.decades_sect1 {
  width: 100%;
  max-width: 903px;
  margin: 0;
  padding: 16px 0;
  position: relative;
}

.decades_sect1 .centerimg {
  margin: 50px auto 0;
  display: block;
}

.decades_sect1 span.pos_decade46 {
  position: absolute;
  margin-top: -258px;
  margin-left: 14px;
}

.decades_sect1 span.pos_decade47 {
  position: absolute;
  margin-top: -265px;
  margin-left: 590px;
}

.decades_sect1 span.pos_decade48 {
  position: absolute;
  margin-top: -118px;
  margin-left: 569px;
}

.decades_sect1 span.pos_decade49 {
  position: absolute;
  margin-top: -92px;
  margin-left: 22px;
}

.bar_section img.pos_decade01 {
  position: absolute;
  margin-top: -2px;
  margin-left: 415px;

}

.bar_section img.pos_decade02 {
  position: absolute;
  margin-top: -2px;
  margin-left: 318px;

}

.bar_section img.pos_decade03 {
  position: absolute;
  margin-top: -2px;
  margin-left: 217px;
}

.bar_section img.pos_decade04 {
  position: absolute;
  margin-top: -2px;
  margin-left: 120px;
}

.bar_section img.pos_decade05 {
  position: absolute;
  margin-top: -2px;
  margin-left: 22px;
}

.right_img img.pos_decade06 {
  margin-top: -315px;
  margin-left: 665px;
}

.oxetol_blog_middle span.pos_decade07 {
  position: absolute;
  margin-top: -302px;
  margin-left: -204px;
}

.oxetol_blog_middle span.indian_data_2 {
  position: absolute;
  right: -55px;
  top: -161px;
}

.oxetol_blog_middle span.indian_data_1 {
  position: absolute;
  right: -38px;
  bottom: 60px;
}

.oxetol_blog_middle span.pos_decade08 {
  position: absolute;
  margin-top: 149px;
  margin-left: 102px;
}

.oxetol_blog_middle span.pos_decade09 {
  position: absolute;
  margin-top: 97px;
  margin-left: -68px;
}

.oxetol_blog_middle span.pos_decade10 {
  position: absolute;
  margin-top: 4px;
  margin-left: -235px;
}

.oxetol_blog_middle span.pos_decade11 {
  position: absolute;
  margin-top: 136px;
  margin-left: 274px;
}

.oxetol_blog_middle span.pos_decade12 {
  position: absolute;
  margin-top: 176px;
  margin-left: 148px;
}

.oxetol_blog_middle span.pos_decade13 {
  position: absolute;
  margin-top: 160px;
  margin-left: 20px;
}

.oxetol_blog_middle span.pos_decade14 {
  position: absolute;
  margin-top: 80px;
  margin-left: -105px;
}

.oxetol_blog_middle span.pos_decade15 {
  position: absolute;
  margin-top: 67px;
  margin-left: -230px;
}

.oxetol_blog_middle span.pos_decade16 {
  position: absolute;
  margin-top: 41px;
  margin-left: -360px;
}

.oxetol_blog_middle span.pos_decade55 {
  position: absolute;
  top: -28px;
  left: 207px;
}

.oxetol_blog_middle span.pos_decade56 {
  position: absolute;
  top: 102px;
  left: 168px;
}

.oxetol_blog_middle span.pos_decade57 {
  position: absolute;
  top: 234px;
  left: 207px;
}

.oxetol_blog_middle span.pos_decade58 {
  position: absolute;
  top: -28px;
  left: 722px;
}

.oxetol_blog_middle span.pos_decade59 {
  position: absolute;
  top: 102px;
  left: 750px;
}

.oxetol_blog_middle span.pos_decade60 {
  position: absolute;
  top: 234px;
  left: 722px;
}





.bar_section span.pos_decade17 {
  position: absolute;
  left: 337px;
  top: -7px;
}

.bar_section span.pos_decade18 {
  position: absolute;
  left: 21px;
  top: -6px;
}

.bar_section span.pos_decade19 {
  position: absolute;
  left: 233px;
  top: -7px;
}

.bar_section span.pos_decade20 {
  position: absolute;
  left: 128px;
  top: -7px;
}

.bar_section span.pos_decade21 {
  position: absolute;
  left: 446px;
  top: -7px;
}

.bar_section span.pos_decade22 {
  position: absolute;
  left: 20px;
  top: -13px;
}

.bar_section span.pos_decade23 {
  position: absolute;
  left: 129px;
  top: -13px;
}

.bar_section span.pos_decade24 {
  position: absolute;
  left: 235px;
  top: -17px;
}

.bar_section span.pos_decade25 {
  position: absolute;
  left: 345px;
  top: -13px;
}

.bar_section span.pos_decade26 {
  position: absolute;
  left: 451px;
  top: -13px;
}

.pana_sect.zep_sect .oxetol_blog_middle span img {
  margin: 0;
}

.pana_sect.zep_sect .oxetol_blog_middle span {
  padding: 7px 0 10px;
  display: block;
}

.decades_sect2 {
  padding: 10px 0 0;
}

.pana_sect.zep_sect .bar_section {
  width: 729px;
  margin: 6px 0 0;
}

.pana_sect.zep_sect .bar_cont {
  background: url(../images/pana_img30.png)no-repeat left top;
}

.pana_sect.zep_sect .bar_blog_abs {
  width: 615px;
  height: 265px;
  position: relative;
  left: 78px;
  bottom: 21px;
  top: auto;
  overflow: hidden;
}

.pana_sect.zep_sect .bar2 {
  left: 18px;
  top: 3px;
}

.pana_sect.zep_sect .bar3 {
  left: 155px;
  top: 3px;
}

.pana_sect.zep_sect .bar5 {
  left: 494px;
  top: -1px;
}

.pana_sect.zep_sect .bar4 {
  left: 362px;
  top: 2px;
}

.pana_sect.zep_cont .oxetol_blog_middle span img {
  margin: 0;
}

.pana_sect.zep_cont .bar_section {
  width: 842px;
}

.pana_sect.zep_cont .bar_cont {
  background: url(../images/pana_img44.png)no-repeat left top;
  height: 345px;
  margin: 0 0 10px;
}

.pana_sect .bar_blog_abs {
  width: 654px;
  height: 209px;
  position: relative;
  left: 81px;
  top: -38px;
  overflow: hidden;
}

.zep_cont .bar_blog_abs {
  width: 715px;
  height: 224px;
  position: relative;
  left: 84px;
  top: -34px;
  overflow: hidden;
}

/* .paxi_sect, .liof_sect    {  width: 100%; height: 100vh; margin: 0 auto; padding: 0; background: url("../images/paxi_img2.png")no-repeat left top; background-size: 100% 100%; display: flex !important;
  justify-content: center;
  align-items: center;
} */

.paxi_sect {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  padding: 0;
  background: url("../images/paxi_img2.png")no-repeat left top;
  background-size: 100% 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

/* .paxi_cont, .liof_cont { width: auto; height: 690px; position: relative;}
.paxi_cont img, .liof_cont img  { width: auto; height:auto; margin: 0 auto; padding: 0; }
.paxi_cont img.pos_rel,
.liof_cont img.pos_rel { position: relative; z-index: 9;}
.btm_img, .liof_btm_img    { width: auto; position: relative; margin: -21px 0 0;}
.btm_img span, .liof_btm_img span  { display: inline-block;} */
.btm_img span.left_img {
  position: absolute;
  left: -220px;
  top: -38px;
}

.btm_img span.right_img {
  position: absolute;
  right: -220px;
  top: -44px;
}

.liof_sect {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  padding: 0;
  position: relative;
  background: url(../images/paxi_img2.png) no-repeat left top;
  background-size: 100% 100%;
}

.liof_sect span {
  width: 859px;
  margin: 0 auto;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 30px;
}

.liof_cont {
  width: 859px;
  margin: 0 auto;
  padding: 35px 0 24px;
  position: relative;
}

.liof_cont img {
  width: auto;
  margin: 0 auto;
  display: block;
}

.liof_cont a {
  position: absolute;
  width: 195px;
  height: 195px;
  width: auto;
  height: auto;
  border-radius: 50%;
  display: block;
}

.liof_cont a.center_img {
  top: 100px;
  left: 278px;
  top: 104px;
  left: 268px;
  z-index: 9;
}

.liof_cont a.left_img {
  top: 100px;
  left: 278px;
  top: 291px;
  left: -41px;
}

.liof_cont a.right_img {
  top: 100px;
  left: 278px;
  top: 290px;
  left: 448px;
}


.liof_btm_img span.left_img {
  position: absolute;
  left: -160px;
  top: -350px;
  z-index: 9;
}

.liof_btm_img span.right_img {
  position: absolute;
  right: -150px;
  top: -350px;
  z-index: 9;
}

.liof_btm_img span.center_img {
  position: absolute;
  left: 150px;
  top: -539px;
  z-index: 10;
}

.paxi_cont {
  width: auto;
  height: 690px;
  position: relative;
}

.paxi_cont img {
  width: auto;
  height: auto;
  margin: 0 auto;
  padding: 0;
}

.paxi_cont img.pos_rel {
  position: relative;
  z-index: 9;
}

.btm_img {
  width: auto;
  position: relative;
  margin: -21px 0 0;
}

.btm_img span {
  display: inline-block;
}

.paxicr_sect .oxetol_blog_top_right {
  padding: 33px 0 0;
}

.paxicr_sect .oxetol_blog_top_right span {
  margin: 0 0 33.5px 35px;
  display: block;
}

.paxicr_sect .bar_section {
  width: 750px;
}

.paxicr_sect .bar_cont {
  background: url(../images/paxi_img17.png)no-repeat left top;
  height: 338px;
  margin: 0 0 10px;
}

.paxicr_sect .bar_blog_abs {
  width: 660px;
  height: 255px;
  position: relative;
  left: 69px;
  top: -22px;
  overflow: hidden;
}

.paxicr_sect .oxetol_blog_footer_right ul li.active a,
.paxicr_sect .oxetol_blog_footer_right ul li a:hover {
  background-color: #24b8cd;
}


.paxicr_txt1 .bar_section {
  width: 746px;
}

.paxicr_txt1 .bar_section .bar_cont {
  background: url(../images/paxi_img28.png)no-repeat left top;
  height: 339px;
}

.paxicr_sect.paxicr_txt1 .bar_blog_abs {
  width: 557px;
  height: 219px;
  position: relative;
  left: 111px;
  top: -17px;
  overflow: hidden;
}

.paxicr_sect.paxicr_txt2 .bar_blog_abs {
  width: 590px;
  height: 230px;
  position: relative;
  left: 72px;
  top: -24px;
  overflow: hidden;
}

.paxicr_sect.paxicr_txt1 .bar3 {
  left: 401px;
}

.paxicr_sect.paxicr_txt1 .bar2 {
  left: 209px;
}

.paxicr_sect.paxicr_txt1 .bar1 {
  left: 19px;
}

.paxicr_sect.paxicr_txt1 .right_img {
  margin: 0;
}

.paxicr_txt2 .bar_section {
  width: 745px;
}

.paxicr_txt2 .bar_section .bar_cont {
  background: url(../images/paxi_img36.png)no-repeat left top;
  height: 340px;
}

.paxicr_txt3 .oxetol_blog_middle img.mar_chnge {
  margin: 0 10px;
}

.top_img {
  width: 100%;
  text-align: left;
}

.top_img span {
  display: inline-block;
  margin: 0 10px;
  padding: 15px 0 10px;
}

.top_img.padd_chge span {
  padding: 10px 0;
}

.paxicr_txt3 .open_sect {
  padding: 7px 10px 7px 39px
}

.sizo .bar_section {
  width: 637px;
}

.sizo .bar_section .bar_cont {
  background: none;
  height: auto;
}

.sizo_sect1 .bar_section {
  width: 620px;
}

.sizo_sect1 .bar_section .bar_cont {
  background: url(../images/sizo_img19.png)no-repeat left top;
  height: 515px;
}

.sizo_sect1 .bar_blog_abs {
  width: 505px;
  height: 236px;
  position: relative;
  left: 95px;
  bottom: auto;
  top: -72px;
  overflow: hidden;
}


.paxicr_sect.paxicr_txt2 .bar2 {
  left: 370px;
  top: 84px;
}

.paxicr_sect.paxicr_txt2 .bar1 {
  left: 49px;
  top: 15px;
}

.paxicr_txt3 .oxetol_blog_middle img {
  margin: 0 auto;
}

.sizo_sect .oxetol_blog_top_right span {
  margin: 0 0 14px 35px;
  display: block;
}

.sizo_sect .oxetol_blog_footer_right button,
.sizo_sect1 .oxetol_blog_footer_right button {
  margin-right: 60px;
}

.sizo_sect .bar_section {
  width: auto;
}

.sizo_sect .bar_cont {
  height: auto;
  background: transparent;
}

.sizo_sect .oxetol_blog_footer_right ul li.active a,
.sizo_sect .oxetol_blog_footer_right ul li a:hover,
.sizo_sect1 .oxetol_blog_footer_right ul li.active a,
.sizo_sect1 .oxetol_blog_footer_right ul li a:hover {
  background-color: #009846;
}

.sizo_sect1 .oxetol_blog_top_right span {
  margin: 0 0 14px 35px;
  display: block;
}

.beta_sect {
  width: 1080px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
}

.beta_img {
  width: 678px;
  height: 678px;
  position: absolute;
  left: 358px;
  top: 0;
  overflow: hidden;
}

.beta_abs {
  position: absolute;
  left: 295px;
  top: -49px;
}

.beta_abs1 {
  position: absolute;
  left: 382px;
  top: 109px;
}

.beta_abs2 {
  position: absolute;
  left: 381px;
  bottom: 109px;
}

.beta_abs3 {
  position: absolute;
  left: 293px;
  bottom: -48px;
}

.beta_bg {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  padding: 0;
  position: relative;
  background: url(../images/paxi_img2.png)no-repeat left top;
  background-size: 100% 100%;
}

.beta_cont {
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

.beta_cont span {
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  display: block;
}

.beta_img {
  width: 678px;
  height: 678px;
  position: absolute;
  left: 358px;
  top: 0;
  overflow: hidden;
}

.beta_abs {
  position: absolute;
  left: 295px;
  top: -49px;
}

.beta_abs1 {
  position: absolute;
  left: 382px;
  top: 109px;
}

.beta_abs2 {
  position: absolute;
  left: 381px;
  bottom: 109px;
}

.beta_abs3 {
  position: absolute;
  left: 293px;
  bottom: -48px;
}

.beta_tr_cont {
  width: 890PX;
  margin: 20px auto;
  padding: 0;
}

.beta_tr_cont_middle {
  margin-bottom: 0px;
}

.beta_tr_cont_middle.beta_tr_cont_middle_1 {
  margin-left: 100px;
}

.beta_tr_cont.beta_tr_cont_gaba {
  margin-left: 100px;
}

.beta_tr_blog {
  width: 52%;
  display: inline-block;
  position: relative;
  overflow: hidden;
  MARGIN: 18px 0;
}

.beta_tr_blog.wid_chge1-1 {
  width: 54%;
}

.beta_tr_blog.wid_chge1-2 {
  width: 45%;
}

.beta_tr_blog img {
  position: relative;
  z-index: 99;
}

.beta_tr_blog span {
  width: auto;
  position: absolute;
  left: 98px;
  top: 12px;
  padding: 0;
}

.beta_tr_blog_gaba span {
  left: 185px;
  top: 5px;
}

.beta_tr_blog.beta_tr_blog_gaba {
  width: 100%;
  margin: 0px;
}

.beta_tr_blog.beta_tr_blog_gaba.beta_tr_blog_gaba_1 {
  margin-bottom: 18px;
}

.beta_tr_blog.wid_chge1-1 span,
.beta_tr_blog.wid_chge1-2 span {
  top: 13px;
}

.width45percent {
  width: 45% !important;
}

.beta_slide1 .oxetol_blog_top_right span {
  margin: 0 0 25px 35px;
  display: block;
}

.beta_slide1 .open_sect {
  padding: 7px 10px 7px 39px
}

.beta_slide1 .oxetol_blog_footer_right ul li.active a,
.beta_slide1 .oxetol_blog_footer_right ul li a:hover {
  background-color: #c81e7a;
}

.beta_slide2 .bar_section {
  width: 704px;
}

.beta_slide2 .bar_section .bar_cont {
  background: url(../images/beta_img35.png)no-repeat left top;
  height: 292px;
}

.middletopright {
  float: right;
  margin-right: 68px;
  margin-top: -60px;
  background: none;
  border: none;
}

.middletopright.removemargin {
  margin-top: -80px;
}

.middletopright.onceaday1 {
  margin-top: -70px;
}


.caris_slide3 .oxetol_blog_footer_right {
  bottom: 4px;
}

.caris_slide3 .oxetol_blog_footer_right button {
  margin-right: 75px;
}

.caris.caris_slide3 .oxetol_blog_footer_right ul {
  bottom: 22px;
}

.beta_slide1 .bar_section {
  width: 710px;
}

.beta_slide1 .bar_cont_beta_2.beta_bar1.beta_bar2 {
  background: url(../images/betacap_img14_1.png)no-repeat left top;
  height: 295px;
  width: 100%;
  outline: none;
  border: 0;
}

.beta_slide1 .bar_blog_abs {
  width: 575px;
  height: 197px;
  position: relative;
  left: 88px;
  bottom: 8px;
  top: auto;
  overflow: hidden;
}

.beta_slide1 .bar2 {
  left: 352px;
  top: 9px;
}

.beta_slide1 .bar1 {
  left: 70px;
  bottom: -38px;
  top: auto;
}

.beta_slide1 .open_sect.beta_open1 {
  padding: 6px 10px 7px 39px;
}

.beta_slide1 .open_sect {
  padding: 12px 10px 12px 39px;
}

.beta_slide3 .oxetol_blog_top_right span {
  margin: 0 0 24px 35px;
}

.beta_slide3 .oxetol_blog_top_right {
  padding: 30px 0 0;
}

.beta_slide3 .bar_section {
  width: 750px;
}

.beta_slide3 .bar_section .bar_cont {
  background: url(../images/beta_img42.png)no-repeat left top;
  height: 292px;
}

.beta_slide3 .bar_blog_abs {
  width: 577px;
  height: 247px;
  position: relative;
  left: 100px;
  top: auto;
  overflow: hidden;
  bottom: 34px;
}

.beta_slide3 .bar1 {
  left: 52px;
  top: auto;
  bottom: 0;
}

.beta_slide3 .bar2 {
  left: 208px;
  top: auto;
  bottom: 0;
}

.beta_slide3 .bar3 {
  left: 350px;
  top: auto;
  bottom: 0;
}

.beta_slide3 .bar4 {
  left: 512px;
  top: auto;
  bottom: 0;
}

.beta_slide3 .oxetol_blog_footer_right ul li.active a,
.beta_slide3 .oxetol_blog_footer_right ul li a:hover {
  background-color: #c81e7a;
}

.beta_slide4 .oxetol_blog_top_right span {
  margin: 0 0 12px 35px;
}

.beta_slide4 .oxetol_blog_top_right {
  padding: 31px 0 0;
}

.beta_slide4 .bar_section {
  width: 777px;
}

.beta_slide4 .bar_section .bar_cont {
  background: url(../images/beta_plus_img11.png)no-repeat left top;
  height: 326px;
}

.beta_slide4 .bar_blog_abs {
  width: 680px;
  height: 232px;
  position: relative;
  left: 79px;
  top: auto;
  overflow: hidden;
  bottom: 22px;
}

.beta_slide4 .bar3 {
  left: 493px;
}

.beta_slide4 .bar1 {
  left: 34px;
  top: 7px;
}

.beta_slide4 .bar2 {
  left: 271px;
  top: 15px;
}

.beta_slide4 .oxetol_blog_footer_right ul li.active a,
.beta_slide4 .oxetol_blog_footer_right ul li a:hover {
  background-color: #c81e7a;
}

.toppading8 {
  padding-top: 8px;
  ;
}

.veniz_pop1,
.oxet_pop1,
.oxet_pop2 {
  padding: 0px !important;
  border: none;
  background: transparent;
}

.marginright24 {
  margin-right: 24px;
}

.logopaddingtopbottom {
  padding: 10px 0px 12px 0px;
}

.removed_top_padding {
  padding-top: 0px;
}

.zoom_sect {
  position: relative;
  right: -180px;
  /* top: 40%; */
  position: absolute;
  right: 0;
  top: 40%;
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
}

.zoom_sect img {
  transition: 0.3s;
}

.zoom_sect:hover img {
  transform: scale(1.1);
}

/*---------------------------------------
    Park Slide - slide 20              
-----------------------------------------*/

.park_sect {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  background: url(../images/neu_bg.jpg)no-repeat left top;
  background-size: 100% 100%
}

.park_sect img {
  display: block;
  margin: 0 auto;
}

.paramainimg1 {
  padding: 123px 0 30px;
}

.paramainimg2 {
  padding: 0 0 22px;
}

.paramainimg3 {
  padding: 0 0 63px;
}

.park_sect a {
  width: 648px;
  height: 202px;
  border-radius: 80px;
  position: absolute;
  left: 190px;
  top: 400px;
}

.park {
  /* position: absolute;
  bottom: 20px; */
  width: 100%;
}

.command_ftr_img_cont {
  width: 100%;
  max-width: 961px;
  margin: 0 auto;
  position: relative;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.command_ftr_img_cont a {
  width: 298px;
  height: 110px;
  border-radius: 80px;
  position: absolute;
}

.paramain_bottomleft {
  margin-left: 0;
  margin-top: 100px;
  border: none;
  background: none;
}

.paramain_bottomright {
  margin-left: 0;
  margin-top: 100px;
  border: none;
  background: none;
}

.command_ftr_img_cont span {
  margin-left: 0;
  margin-top: 135px;
}

/*---------------------------------------
    Park Slide - slide 20_1              
-----------------------------------------*/

.park_sect1 .oxetol_blog_top_right span {
  margin: 0 0 35px 35px;
  display: block;
}

.park_cont1 {
  width: 100%;
  margin: 0;
  padding: 0;
}

.park_cont1 .bar_section {
  width: 716px;
  display: inline-block;
  vertical-align: bottom;
}



.park_cont1 span {
  display: inline-block;
  vertical-align: bottom;
}

.park_sect1 .oxetol_blog_middle span.title4 {
  padding: 15px 0 0;
}

.park_cont1 span {
  padding: 10px 0;
  margin-left: 90px;
  position: absolute;
  bottom: 0;
}

.park_cont1 .bar_blog_abs {
  width: 515px;
  height: 220px;
  left: 112px;
  top: -19px;
}


.neuro_sect .oxetol_blog_top_right span {
  margin: 0 0 19px 30px;
  display: block;
}

.neuro_sect .oxetol_blog_top_right {
  padding-top: 30px;
}

.neuro_sect_left {
  display: inline-block;
  vertical-align: middle;
}

.neuro_sect_right {
  display: inline-block;
  vertical-align: middle;
  float: right;
}

.neuro_sect .oxetol_blog_middle span {
  padding: 15px 0 0;
  display: block;
}

.neuro_sect .oxetol_blog_middle {
  max-width: 790px;
}

.neuro_sect .dream_sect img {
  padding: 1px 0 0;
}

.gv_padd5 {
  padding-top: 5px;
}

.pira_sect .oxetol_blog_top_right {
  padding: 30px 0 0;
}

.pira_sect .oxetol_blog_top_right span {
  margin: 0 0 22px 35px;
  display: block;
}

.pira_sect .dream_sect img {
  padding: 0 0 0;
  margin: 0;
  position: absolute;
  left: 6px;
  bottom: -37px;
}

.pira_sect .dream_sect {
  position: relative;
}

.pira_sect .open_sect {
  padding: 7px 10px 7px 39px;
}

.pira_sect .blue_text {
  width: 100%;
  padding: 0;
  margin: 0 0 30px;
}

.pira_sect .green_text {
  width: 100%;
  padding: 0;
  margin: 0;
}

.pira_sect .blue_text span,
.pira_sect .green_text span {
  padding: 10px 0 0;
}

.pira_sect .oxetol_blog_middle {
  margin-top: 10px;
}

.pira_sect .oxetol_blog_footer_right ul li.active a,
.pira_sect .oxetol_blog_footer_right ul li a:hover {
  background-color: #28a35f;
}


/*---------------------------------------
    Pira Slide - slide 18_2             
-----------------------------------------*/

.pira_sect1 .oxetol_blog_top_right span {
  margin: 0 0 22px 35px;
  display: block;
}

.pira_sect1 .dream_sect img {
  padding: 0 0 0;
  margin: 0;
  position: absolute;
  left: 6px;
  bottom: -37px;
}

.pira_sect1 .dream_sect {
  position: relative;
}

.pira_sect1 .blue_text {
  width: 100%;
  padding: 0;
  margin: 0 0 35px;
}

.pira_sect1 .green_text {
  width: 100%;
  padding: 0;
  margin: 0;
}

.pira_sect1 .oxetol_blog_footer_right ul li.active a,
.pira_sect1 .oxetol_blog_footer_right ul li a:hover {
  background-color: #28a35f;
}

.pira_sect1 .oxetol_blog_top_right {
  padding: 30px 0 0;
}

.dis_chnge {
  float: left;
  width: auto;
  margin: 0 0 0 !important;
}

.dis_chnge.logomarginbot24 {
  margin-bottom: 24px !important;
}

.dis_chnge1 {
  float: right;
  width: auto;
  margin: 35px 0 0;
}

.dis_chnge.liofen_padd span {
  margin: 0 0 17px 39px;
}

.dis_chnge1.padd_chnge4 {
  margin: 35px 5px 0 0;
}

.oxetol_blog_top_right.liofen_right {
  margin: 0;
  padding: 20px 0 0;
}

.oxetol_blog_top_right.liofen_right.liquid_padd {
  padding: 10px 0 0;
}

.sizo_sect .oxetol_blog_top_right span.dis_chnge1 {
  margin: 20px 15px 0 0;
}

.removemargintoprightspanbottom {
  margin-bottom: 0px !important;
}

.luratitleposition {
  left: 40px !important;
  bottom: 3px !important;
}

.paddingbottom0 {
  padding-bottom: 0px !important;
}

.refpaddtopbottom8 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  bottom: 8px !important;
}

.margin0auto {
  margin: 0 auto !important;
}

.margintop30 {
  margin-top: 30px !important;
}

.margintop50 {
  margin-top: 50px !important;
}

.marginbottom8 {
  margin-bottom: 8px !important;
}

.marginleftremove40 {
  margin-left: -40px;
}

.paddingbottom8 {
  padding-bottom: 8px;
}
.addtoppadding110_48{
  padding-left: 68px;
  padding-top: 18px;
}


.lamotitilereposition {
  left: 40px !important;
  bottom: 7px !important;
}

.floatright {
  float: right;
}

.paddingtop0 {
  padding-top: 0px !important;
}

.paddingbottom13 {
  padding-bottom: 13px;
}

.acamtitilereposition {
  left: 40px !important;
  bottom: 3px !important;
}

/*---------------------------------------
    Nodict Slide - slide 14_1           
-----------------------------------------*/

.nodi_sect .oxetol_blog_top_right span {
  margin: 0 0 13px 35px;
  display: block;
}

.nodi_sect .oxetol_blog_middle span img {
  margin: 0 auto;
  display: block;
}

.nodi_sect .oxetol_blog_middle span {
  padding: 10px 0 0;
}

/*---------------------------------------
    ADES Slide - slide 15_1          
-----------------------------------------*/

.ades_sect .oxetol_blog_top_right span {
  margin: 0 0 9px 35px;
  display: block;
}

.ades_cont {
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

.ades_cont img.middle_img {
  margin: 0 auto;
  display: block;
}

.ades_cont span {
  position: absolute;
}

.ades_cont span.purple_img {
  right: 145px;
  top: 4px;
}

.ades_cont span.pink_img {
  left: 136px;
  top: 111px;
}

.ades_cont span.blue_img {
  right: 137px;
  top: 205px;
}

.ades_cont span.green_img {
  left: 138px;
  bottom: 3px;
}

/*---------------------------------------
    Topirol Slide - slide 16          
-----------------------------------------*/

.topi_sect {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 115px 0 0;
  background: url(../images/paxi_img2.png) no-repeat left top;
  background-size: 100% 100%;
  position: relative;
}

.topi_cont {
  width: 100%;
  margin: 0;
  padding: 75px 0 0;
  position: relative;
}

.topi_cont img.middle_img1 {
  margin: 0 auto;
  display: block;
}

.addleftpadding10 {
  padding-left: 10px;
}
.addleftpadding70 {
  padding-left: 70px;
}

.addleftpadding48 {
  padding-left: 60px;
}
.addleftpadding48.addtoppadding17{
  padding-top: 17px; 
}

.addleftpadding48.addtoppadding37 {
  padding-top: 42px;
  border-radius: 110% 13px 13px 13px / 100% 13px 13px 13px;
}
.addleftpaddingtop30 {
  padding-left: 48px;
  padding-top: 30px;
}
.addleftpadding48.addtoppadding15{
  padding-top: 15px; 
}
.addleftpadding48.addtoppadding33{
  padding-top: 33px; 
}
.addleftpadding48.addtoppadding30_1{
  padding-top: 30px; 
}
.addleftpadding48.addtoppadding35{
  padding-top: 35px; 
}
.addleftpadding48.addtoppadding30{
  padding-top: 32px; 
}
/* PHY CODE */
.addtoppadding_48{
  padding-left: 65px;
}
.addtoppadding18_48{
  padding-top: 18px; 
  padding-left: 48px;
}
.addleftpadding48.addtoppadding38{
  padding-top: 37px; 
}
.addtoppadding29
{
  padding-top: 29px; 
}
.addtoppadding16
{
  padding-top: 16px; 
}
.oxetol_blog_middle span.margintop50 {
  margin-top: 70px !important;
  margin-right: 150px;
}

.pira_sect.acam_sect .open_sect {
  padding: 4px 10px 4px 39px;
}

.pira_sect.acam_sect .oxetol_blog_top_right span {
  margin: 0 0 21px 35px;
  display: block;
}

.pira_sect.acam_sect .oxetol_blog_middle {
  padding: 0 0 10px 70px;
}

.ades_sect .oxetol_blog_middle {
  padding: 10px 0;
}

.topi_cont a {
  position: absolute;
}

.topi_cont a.blue_img1 {
  bottom: -160px;
  left: 125px;
}

.topi_cont a.yellow_img1 {
  bottom: -160px;
  left: 295px;
}

.topi_cont a.red_img1 {
  bottom: -160px;
  left: 458px;
}

.topi_cont a.purple_img1 {
  bottom: -160px;
  left: 586px;
  z-index: 9;
}

.topi_cont a.gray_img1 {
  bottom: -160px;
  left: 705px;
}

.topi_sect p {
  position: absolute;
  left: 70px;
  bottom: 30px;
  margin: 0;
}

.topi_sect_new .oxetol_blog_top_right span {
  margin: 34px 0 34px 35px;
  display: block;
}

.topi_sect_new .bar_section {
  width: 824px;
}

.topi_sect_new .bar_section .bar_cont {
  height: 345px;
  background: url(../images/topi_img18.png) no-repeat left top;
}

.topi_sect_new .bar_blog_abs {
  width: 701px;
  height: 253px;
  left: 92px;
  bottom: 16px;
  top: inherit;
}

.topi_sect_new .bar1 {
  left: 51px;
  top: inherit;
  bottom: 0;
}

.topi_sect_new .bar2 {
  left: 286px;
  top: inherit;
  bottom: 0;
}

.topi_sect_new .bar3 {
  left: 523px;
  top: inherit;
  bottom: 0;
}

.topi_sect_new .oxetol_blog_middle span {
  padding: 20px 0 25px;
  display: block;
}

.topi_sect_new .oxetol_blog_footer_right ul li.active a,
.topi_sect_new .oxetol_blog_footer_right ul li a:hover {
  background-color: #4f7349;
}

.topi_sect_new.topi_sect2 .bar_section {
  width: 674px;
}

.topi_sect_new.topi_sect2 .bar_section .bar_cont {
  height: 345px;
  background: url(../images/topi_img26.png) no-repeat left top;
}

.topi_sect_new.topi_sect2 .bar_blog_abs {
  width: 701px;
  height: 253px;
  left: 92px;
  bottom: 16px;
  top: inherit;
}

.topi_sect_new.topi_sect2 .bar1 {
  left: 51px;
  top: inherit;
  bottom: 0;
}

.topi_sect_new.topi_sect2 .bar2 {
  left: 338px;
  top: inherit;
  bottom: 0;
}

.topi_sect_new.topi_sect5 .bar_section {
  width: 597px;
}

.topi_sect_new.topi_sect5 .bar_section .bar_cont {
  height: 325px;
  background: url(../images/topi_img55.png) no-repeat left top;
}

.topi_sect_new.topi_sect5 .bar_blog_abs {
  width: 471px;
  height: 185px;
  left: 87px;
  bottom: 38px;
  top: inherit;
}

.topi_sect_new.topi_sect5 .bar1 {
  left: 75px;
  top: inherit;
  bottom: 23px;
}

.topi_sect_new.topi_sect3 .bar_section {
  width: 777px;
  height: 330px;
  background: url(../images/topi_img40.png);

}

.bar_cont_new {
  width: 386px;
  height: 153px;
  display: inline-block;
  margin: 0 0 10px;
  padding: 0;
  border: none;
  background: transparent;
}

.topi_sect_new.topi_sect3 .oxetol_blog_middle span {
  padding: 15px 0 25px;
}

.topi_sect_new.topi_sect3 .oxetol_blog_middle span:first-child {
  padding: 15px 0 10px;
}

.topi_sect_new.topi_sect3 .oxetol_blog_middle,
.topi_sect_new .oxetol_blog_middle {
  padding: 0 0 10px 75px;
}

.topi_sect_new.topi_sect3 .bar_blog_abs {
  width: 289px;
  height: 115px;
  left: 72px;
  bottom: 7px;
  top: inherit;
}

.topi_sect_new.topi_sect3 .bar1 {
  left: 18px;
  top: inherit;
  bottom: 0;
}

.topi_sect_new.topi_sect3 .bar2 {
  left: 184px;
  top: inherit;
  bottom: 0;
}

.topi_sect_new.topi_sect3 .bar3 {
  left: 13px;
  top: inherit;
  bottom: 0;
}

.topi_sect_new.topi_sect3 .bar4 {
  left: 180px;
  top: inherit;
  bottom: 0;
}

.topi_sect_new.topi_sect3 .bar5 {
  left: 18px;
  top: inherit;
  bottom: 0;
}

.topi_sect_new.topi_sect3 .bar6 {
  left: 184px;
  top: inherit;
  bottom: 0;
}

.topi_sect_new.topi_sect3 .bar7 {
  left: 13px;
  top: inherit;
  bottom: 0;
}

.topi_sect_new.topi_sect3 .bar8 {
  left: 180px;
  top: inherit;
  bottom: 0;
}

.topi_sect_new.topi_sect4 .bar_section {
  width: 734px;
}

.topi_sect_new.topi_sect4 .bar_section .bar_cont {
  height: 341px;
  background: url(../images/topi_img47_1.png) no-repeat left top;
}

.topi_sect_new.topi_sect4 .bar_blog_abs {
  width: 638px;
  height: 145px;
  left: 64px;
  bottom: inherit;
  top: 23px;
}

.topi_sect_new.topi_sect4 .bar1 {
  left: 19px;
  top: 0;
  bottom: inherit;
}

.topi_sect_new.topi_sect4 .bar2 {
  right: 0;
  top: 0;
  bottom: inherit;
  left: auto;
}

.etirest .oxetol_blog_top_right span.logo.logogaba1 {
  padding: 9px 0 22px 0;
  margin: 0 0 0 30px;
}

.etirest .dream_sect.gv_padd {
  padding: 0 0 0 40px;
}

.etirest .oxetol_blog_middle span {
  margin: 0 0 15px;
  padding: 0;
}

.etirest .oxetol_blog_middle {
  padding: 16px 0 0;
  max-width: 930px;
}

.etirest .oxetol_blog_middle span.incre_padd {
  padding: 5px 0 18px;
  margin: 0;
}

.etirest .oxetol_blog_top_right span {
  margin: 0 0 18px 35px;
  display: block;
}

.width100percent {
  width: 100% !important;
}

.height115 {
  height: 115px !important;
}

.NotSelected {
  /* background: #9c9c9c !important;
  color: #eeeeee !important; */
}

/* .oxetol_blog_top_left a img {
  width: 513px !important;
} */

.visibility_visible {
  visibility: visible !important;
}

.bar_cont.pop_sect2 {
  height: 360px !important;
}

.bar_section.cari_graph3 {
  width: 856px !important;
}

.pop_sect3 .bar_blog_abs .bar1 {
  left: 20px;
  top: 2px;
}

.logo.logocari6 {
  padding: 10px 0 7px 0px !important;
}

.logo.logocari4 {
  padding-top: 2.3px !important;
}

.pop_sect6 .bar_blog_abs {
  width: 500px !important;
  height: 259px !important;
  left: 72px !important;
  top: 23px !important;
}

.pop_sect8 .bar_blog_abs {
  width: 500px !important;
  height: 259px !important;
  left: 60px !important;
  top: 15px !important;
}

.pop_sect9 .bar_blog_abs {
  top: 0px;
}

.pop_sect9 .bar_blog_abs .bar1 {
  left: 23px;
  top: 0px;
}

.logo.panalogo {
  padding: 10px 0px 7px 0px !important;
}

.logo.logopaxi {
  padding: 9px 0px 8px 0px !important;
}

.logo.logosizo {
  padding: 11px 0px 8px 0px !important;
}

.logo.logoveniz {
  padding: 11px 0px 3px 0px !important;
}

.logo.logobeta {
  padding: 10px 0 3px 0 !important;
}

.logo.logobeta2 {
  padding: 11px 0 2px 0 !important;
}

.logo.logobeta3 {
  padding: 10px 0 2px 0 !important;
}

.logo.logobeta4 {
  padding: 10px 0 6px 0 !important;
}

.logo.logooxet {
  padding: 19px 0 12px 0 !important;
}

.logo.logoliof1 {
  padding: 10px 0 3px 30px !important;
}

.logo.logoliof3 {
  padding: 13px 0 3px 30px !important;
}

.logo.logolamo {
  padding: 10px 0 3px 0px !important;
}

.logo.logolura {
  margin-bottom: -1px !important;
}

.logo.logoatte {
  margin-bottom: 9.7px !important;
}

.logo.logoacam {
  padding: 8px 0 10px 0 !important;
}

.logo.logonodi {
  padding: 7px 0 12px 0 !important;
}

.logo.logoades {
  padding: 7px 0 11px 0 !important;
}

.logo.logotopi {
  padding: 10px 0 4px 0 !important;
}

.logo.logoetir {
  padding: 10px 0 2px 0 !important;
}

.logo.logopira {
  padding: 10px 0 0px 0 !important;
}

.logo.logoneuro {
  padding: 10px 0 6px 0 !important;
}

.logo.logopark {
  padding: 10px 0 5px 0 !important;
}


.oxetol_blog_top_right.width568 {
  width: 568px !important;
}

.removemarginleft50 {
  margin-left: -50px !important;
}

.width390 {
  width: 390px !important
}

.addmarginleft45 {
  margin-left: 45px !important;
}

.addpaddingleft0 {
  padding-left: 0px !important;
}

.addpaddingleft30 {
  padding-left: 30px !important;
}

.removemarginleft75 {
  margin-left: -75px !important;
}

.addpaddingtop20 {
  padding-top: 20px !important;
}

.addpaddingbottom13 {
  padding-bottom: 13px !important;
}

.open_sect.liof3 {
  padding: 7px 10px 7px 39px !important;
}

.beta_slide1 .bar_blog_abs.oxet1 {
  width: 545px;
  height: 248px;
  position: relative;
  left: 65px;
  bottom: 0px;
  top: -296px;
  overflow: hidden;
}

.beta_slide1 .bar_blog_abs.oxet2 {
  width: 750px;
  height: 248px;
  position: relative;
  left: 58px;
  bottom: 0px;
  top: -295px;
  overflow: hidden;
}

.sizo_sect .bar_blog_abs.liof1 {
  width: 530px;
  height: 275px;
  position: relative;
  left: 99px;
  top: -332px;
  overflow: hidden;
}

.sizo_sect .bar_blog_abs.liof2 {
  width: 546px;
  height: 275px;
  position: relative;
  left: 85px;
  top: -332px;
  overflow: hidden;
}

.marginleft80 {
  margin-left: 180px !important;
}

.removemarginleft30 {
  margin-left: -30px !important;
}

.paddingleft5 {
  padding-left: 5px !important;
}

.caris_link1 {
  position: absolute;
  width: 189px;
  height: 237px;
  margin-top: -296px;
  margin-left: 139px;
  background: transparent;
  border: none;
}

.caris_link2 {
  position: absolute;
  width: 189px;
  height: 237px;
  margin-top: -296px;
  margin-left: 352px;
  background: transparent;
  border: none;
}

.caris_link3 {
  position: absolute;
  width: 189px;
  height: 237px;
  margin-top: -296px;
  margin-left: 555px;
  background: transparent;
  border: none;
}

.caris_link4 {
  position: absolute;
  width: 189px;
  height: 237px;
  margin-top: -296px;
  margin-left: 758px;
  background: transparent;
  border: none;
}

.paddingtop50 {
  padding-top: 50px !important;
}

.paddingleft15 {
  padding-left: 15px !important;
}

.paddingleft120 {
  padding-left: 120px !important;
}

.paddingleft40 {
  padding-left: 40px !important;
}

.paddingleft240 {
  padding-left: 240px !important;
}

.paddingleft155 {
  padding-left: 155px !important;
}

.paddingleft157 {
  padding-left: 157px !important;
}

.paddingleft100 {
  padding-left: 100px !important;
}

.paddingtop35 {
  padding-top: 35px !important;
}

.margintop-188 {
  margin-top: -188px;
}

.margintop-73 {
  margin-top: -73px;
}

.margintop65 {
  margin-top: 65px;
}

.gaba_sect1 .oxetol_blog_top_right span {
  margin: 0 0 35px 35px;
  display: block;
}
.logo.logogaba {
  padding: 16px 0 15px 0 !important;
}

.Home_phy_img{
padding: 147px 120px 145px 13px;
}

.oxetol_blog_top_right .lapluswidth730{
  width: 730px;
  margin: 0;
  float: right;
  padding: 27px 0 0;

}

